import SelectSimpleMerged from 'Components/public/SelectSimpleMerged.js';
import { Lang } from 'Utils';

class SelectValidationPeriodInterval extends SelectSimpleMerged {

  static get properties() {
    return {
      search: { type: Boolean },
      class: { type: String },
    }
  }

  constructor() {
    super();
    this.items = [
      { _id:'minute1', name:{ fr:'1 minute (test)', en:'1 minute (test)' } },
      { _id:'minute5', name:{ fr:'5 minutes  (test)', en:'5 minutes  (test)' } },
      { _id:'hourly', name:{ fr:'Horaire', en:'Hourly' } },
      { _id:'daily', name:{ fr:'Journalier', en:'Daily' } },
      { _id:'weekly', name:{ fr:'Hebdomadaire', en:'Weekly' } },
      { _id:'monthly', name:{ fr:'Mensuel', en:'Monthly' } },
      { _id:'yearly', name:{ fr:'Annuel', en:'Yearly' } },
    ]
    this.itemTitleField = 'name';
  }

  displayFieldsValue(item) {
    return Lang.lookup(item,'name');
  }

}

customElements.define('select-validation-period-interval', SelectValidationPeriodInterval);