import { BaseElement, html, css, formCss } from 'Elements';
import { Lang } from 'Utils';

class SelectEnableR extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`
        sl-option::part(base) {
          line-height:initial;
          padding:2px;
        }

        .spacer {
          height:10px;
        }

        .opac {
          display:flex;
          gap:2px;
          margin-top:2px;
          opacity:0.9;
          font-size:0.9em;
          margin-left:5px;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      label: { type: String },
      class: { type: String },
      helpText: { type: String },
      merged: { type: String },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        french: {
          translation: {
            herited: 'Hérité',
          }
        },
        english: {
          translation: {
            herited: 'Inherited',
          }
        },
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.value = '';
    this.name = '';
    this.items = [
      { value:"true", name:{ fr:'Activé', en:'Enabled' } },
      { value:"false", name:{ fr:'Désactivé', en:'Disabled' } },
    ]

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  firstUpdated() {
    this.select = this.qs('sl-select');
    this._log.debug('firstUpdated: value:', this.value);
  }

  async connectedCallback() {
    await super.connectedCallback();
    window.addEventListener('language-changed', this.onLanguageChange);
  }
  
  async disconnectedCallback() {
    await super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChange);
  }

  onLanguageChange() {
    this.qs('sl-select').requestUpdate();
  }

  async updated(changedProperties) {
    if (changedProperties.has('value')) {
      if (!this.value) return;
      
      //const oldVal = (changedProperties.get('value') || '').toString();
      if (this.value === true || this.value === 'true') {
        this.value = true
        this.requestUpdate();
        return;
      }

      if (this.value === false || this.value === 'false') {
        this.value = false;
        this.requestUpdate();
        return;
      }
    }
  }

  async onChange() {
    const v = this.qs('sl-select').value;
    if (v === 'true') {
      this.value = true;
    } else if (v === 'false') {
      this.value = false;
    } else {
      this.value = '';
    }
    await this.requestUpdate();
    this.dispatchEvent(new CustomEvent('sl-change', { detail: { value:this.value } }));
  }

  renderMerged() {
    if (!this.merged || this.merged === '') return '';
    if (this.value !== '') return '';

    const item = this.items.find(item => item.value === this.merged);

    return html`
      <div part="form-control" class=" form-control form-control--small form-control--has-label ">
        <label part="form-control-label small" class="form-control__label"></label>
        <div part="form-control-input" class="form-control-input opac">
          <div>${Lang.lookup(item, 'name')}</div>
        </div>
      </div>
    `;
  }

  render() {
    
    if (this.value === true) {
      this.selectValue = 'true'
    } else if (this.value === false) {
      this.selectValue = 'false'
    } else if (this.value === '' || this.value === undefined) {
      this.selectValue = ''
    }

    this._log.debug('render: value:', this.value);
    this._log.debug('render: selectValue:', this.selectValue);

    return html`
      <sl-select 
        clearable 
        size="small"
        class="${this.class}"
        max-options-visible="0" 
        label="${this.label}"
        value="${this.selectValue}"
        placeholder="${this._tl('herited')}"
        @sl-change=${this.onChange}
      >
        ${this.items.map(item => html`<sl-option value="${item.value}">${Lang.lookup(item, 'name')}</sl-option>`)}
      </sl-select>
      ${this.renderMerged()}
    `;
  }
}

customElements.define('select-enabler', SelectEnableR);
