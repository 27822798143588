import { Lang } from 'Utils';
import { BaseElement, html, css } from 'Elements';

class SelectSeverity extends BaseElement {
  static get styles() {
    return [
      css`

        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
        }

        div.label {
          white-space:nowrap;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display:flex;
          align-items:center;
          gap:5px;
          padding:1px;
          margin:1px;
          width:100%;
          flex:1;
        }

        select-search {
        }

        .severity_item {
          display:flex;
          align-items:center;
        }
        
      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
      placeholder: { type: String },
      name: { type: String },
      label: { type: String },
    };
  }

  constructor() {
    super();
    this.label = '';
    this.name = '';
    this.placeholder = '';
    this.items = [
      { id: 'P0', name: { fr: 'P0 - Indéterminé',     en: 'P0 - Undetermined' } },
      { id: 'P1', name: { fr: 'P1 - Critique',        en: 'P1 - Critical' } },
      { id: 'P2', name: { fr: 'P2 - Majeur',          en: 'P2 - Major' } },
      { id: 'P3', name: { fr: 'P3 - Modéré',          en: 'P3 - Moderate' } },
      { id: 'P4', name: { fr: 'P4 - Mineur',          en: 'P4 - Minor' } },
      { id: 'P5', name: { fr: 'P5 - Sans incidence',  en: 'P5 - No impact' } },
    ]
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.qs('select-search').value = this.value;
    }
  }

  setCustomValidity(msg) {
    this.qs('select-search').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('select-search').reportValidity();
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  render() {
    return html`
    <div part="form-control" class=" form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <select-search no-search virtualizer value="${this.value}" placeholder="${this.placeholder}" clearable @sl-change=${this.onChange}>
            ${this.items.map(item => html`
              <sl-option value="${item.id}" text="${Lang.lookup(item, 'name')}">
                <span part="prefix" class="severity" style="display: inline-block;width: 1em; height: 1em;  margin-right: 0.5em;background-color:var(--p${item.id.toLowerCase()})"></span>
                ${Lang.lookup(item, 'name')}
              </sl-option>
            `)}
          </select-search>
        </div>
      </div>
    `;
  }
}

customElements.define('select-severity', SelectSeverity);