import SelectSimpleMerged from 'Components/public/SelectSimpleMerged.js';
import { Lang } from 'Utils';

class SelectValidationFollowUp extends SelectSimpleMerged {

  static get properties() {
    return {
      search: { type: Boolean },
      class: { type: String },
    }
  }

  constructor() {
    super();
    this.items = [
      { _id:'never', name:{ fr:'Ne jamais relancer', en:'Never follow up' } },
      { _id:'always', name:{ fr:'Toujours relancer', en:'Always follow up' } },
      { _id:'1', name:{ fr:'Relancer 1 fois', en:'Follow up once' } },
      { _id:'2', name:{ fr:'Relancer 2 fois', en:'Follow up twice' } },
      { _id:'3', name:{ fr:'Relancer 3 fois', en:'Follow up 3 times' } },
      { _id:'4', name:{ fr:'Relancer 4 fois', en:'Follow up 4 times' } },
      { _id:'5', name:{ fr:'Relancer 5 fois', en:'Follow up 5 times' } },
      { _id:'10', name:{ fr:'Relancer 10 fois', en:'Follow up 10 times' } },
    ]
    this.itemTitleField = 'name';
  }

  displayFieldsValue(item) {
    return Lang.lookup(item,'name');
  }

}

customElements.define('select-validation-followup', SelectValidationFollowUp);