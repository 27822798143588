import SelectSimple from 'Components/public/SelectSimple.js';
import { Lang } from 'Utils';

class SelectGender extends SelectSimple {

  static get properties() {
    return {
      search: { type: Boolean },
      class: { type: String },
    }
  }

  constructor() {
    super();
    this.items = [
      { _id:'male', name:{ fr:'Homme', en:'Man' } },
      { _id:'female', name:{ fr:'Femme', en:'Women' } },
    ]
    this.itemTitleField = 'name';
  }

  displayFieldsValue(item) {
    return Lang.lookup(item,'name');
  }

}

customElements.define('select-gender', SelectGender);