import { BaseElement, html, css, formCss } from 'Elements';
import { Lang } from 'Utils';

class SelectPermissions extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`
        sl-option::part(base) {
          line-height:initial;
          padding:2px;
        }

        sl-select {
          max-width:950px;
        }

        .spacer {
          height:10px;
        }

        .opac {
          display:flex;
          gap:2px;
          margin-top:2px;
          opacity:0.8;
        }

        .hidden_label {
          opacity: 0.7;
          transition: opacity 0.3s;
        }

        .hidden_label div {
          max-width: 0;
          overflow: hidden;
          white-space: nowrap;
          transition: max-width 0.3s;
        }

        .hidden_label:hover {
          opacity: 1;
        }

        .hidden_label:hover div {
          max-width: 100px; /* Une valeur suffisamment grande pour couvrir la largeur maximale attendue */
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      label: { type: String },
      class: { type: String },
      helpText: { type: String },
      merged: { type: String },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        french: {
          translation: {
            herited: 'Hérité',
          }
        },
        english: {
          translation: {
            herited: 'Inherited',
          }
        },
      }
    ]
  }

  constructor() {
    super();
    this.debug = false;
    this.value = '';
    this.name = '';
    this.items = [
      { _id:'-', variant:'neutral', icon:'visibility_off', name:{ fr:'Désactivé', en:'Disable' } },
      //{ _id:'*', variant:'danger', icon:'star', name:{ fr:'Contrôle total', en:'Total control' } },
      { _id:'R', variant:'success', icon:'visibility', name:{ fr:'Lecture', en:'Read' } },
      //{ _id:'W', variant:'danger',  icon:'edit', name:{ fr:'Ecriture', en:'Write' } },
      { _id:'A', variant:'primary', icon:'edit', name:{ fr:'Ajouter', en:'Add' } },
      { _id:'M', variant:'warning', icon:'edit', name:{ fr:'Modifier', en:'Modify' } },
      { _id:'D', variant:'danger', icon:'edit', name:{ fr:'Supprimer', en:'Delete' } },
      { _id:'X', variant:'warning', icon:'play_arrow', name:{ fr:'Execution', en:'Execution' } },
    ]

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  firstUpdated() {
    this.select = this.qs('sl-select');
    this.getTag = this.getTag.bind(this);
    this.select.getTag = this.getTag;
    this._log.debug('firstUpdated: value:', this.value);
  }

  async connectedCallback() {
    await super.connectedCallback();
    window.addEventListener('language-changed', this.onLanguageChange);
  }
  
  async disconnectedCallback() {
    await super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChange);
  }

  onLanguageChange() {
    this.qs('sl-select').requestUpdate();
  }

  getTag(option) {
    const variant = option.querySelector('sl-tag').getAttribute('variant');
    const icon = option.querySelector('sl-tag').getAttribute('icon');
    return `
      <sl-tag size="small" variant="${variant}" removable>
        <m-icon name="${icon}" style="padding-right:4px"></m-icon>
        ${option.getTextLabel()}
      </sl-tag>
    `;
  }

  async updated(changedProperties) {
    if (changedProperties.has('value')) {
      if (!this.value) return;
      
      this._log.debug(`updated: value ${changedProperties.get('value')} => ${this.value}`);
      const oldVal = changedProperties.get('value');
      const val = this.value.toString();

      if (oldVal === '-' && val.match(/[RAMDX]/)) {
        this.value = val.replace(/-/g, '');
        this.requestUpdate();
        return;
      }

      if (oldVal && oldVal[0] !== '-' && val[0] === '-') {
        this.value = '-';
        this.requestUpdate();
        return;
      }

      if (val[1] === '*') {
        this.value = '*';
        this.requestUpdate();
        return;
      }

      if (val[0] === '*') {
        this.value = '*';
        this.requestUpdate();
        return;
      }
    }
  }

  async onChange() {
    this.value = this.qs('sl-select').value.join('');
    await this.requestUpdate();
    this.dispatchEvent(new CustomEvent('sl-change', { detail: { value:this.value } }));
  }

  renderMerged() {
    if (!this.merged || this.merged === '') return '';
    if (this.value !== '') return '';

    const permissions = this.merged.split('');
    return html`
      <div part="form-control" class=" form-control form-control--small form-control--has-label ">
        <label part="form-control-label small" class="form-control__label"></label>
        <div part="form-control-input" class="form-control-input opac">
          ${permissions.map(perm => {
            const p = this.items.find(item => item._id === perm);
            return html`<sl-tag size="small" class="hidden_label" variant="${p.variant}">
              <m-icon name="${p.icon}" style="padding-right:4px"></m-icon>
              <div>${Lang.lookup(p, 'name')}</div>
            </sl-tag>`;
          })}
        </div>
      </div>
    `;
  }

  render() {
    
    if (typeof this.value === 'string') {
      this.selectValue = this.value.split('').join(' ');
    }
    //this._log.debug('render: value:', this.value);
    //this._log.debug('render: selectValue:', this.selectValue);

    return html`
      <sl-select 
        clearable 
        multiple 
        hoist
        size="small"
        class="${this.class}"
        max-options-visible="0" 
        label="${this.label}"
        value="${this.selectValue}"
        placeholder="${this._tl('herited')}"
        @sl-change=${this.onChange}
      >
        ${this.items.map(item => html`<sl-option value="${item._id}">
          <sl-tag size="small" icon="${item.icon}" variant="${item.variant}">
            <m-icon name="${item.icon}" style="padding-right:4px"></m-icon>
            ${Lang.lookup(item, 'name')}
          </sl-tag>
        </sl-option>`)}
      </sl-select>
      ${this.renderMerged()}
      <div class="spacer"></div>
    `;
  }
}

customElements.define('select-permissions', SelectPermissions);
